import styled, { css } from 'styled-components/macro';
import { Link } from 'react-navi';

import { device } from 'components/Global/MediaQuery';

export interface ScButtonProps {
  transparent?: boolean;
  autoWidth?: boolean;
  asLink?: boolean;
  small?: boolean;
}

export const ScButton = styled.button<ScButtonProps>`
  width: 100%;
  max-width: ${({ autoWidth }) => (autoWidth ? 'none' : '280px')};
  padding: ${({ asLink, small }) =>
    asLink ? '0' : small ? '11px 18px' : '19px 20px'};
  border-radius: 4px;
  font-weight: 600;
  font-size: ${({ asLink, small }) => (asLink || small ? '14px' : '16px')};
  font-family: Manrope, sans-serif;
  line-height: 1.12;
  text-decoration: ${({ asLink }) => (asLink ? 'underline' : 'none')};
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  @media (${device.mobileXL}) {
    max-width: none;
  }
  .icon {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 14px;
    vertical-align: middle;
    margin-right: 10px;
    svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-55%);
    }
  }

  ${({ transparent, asLink }) => {
    switch (true) {
      case transparent != null:
        return css`
          background-color: transparent;
          border-width: 1px;
          border-style: solid;
          border-color: #bfbfbf;
          color: #ffffff;

          &:hover {
            background-color: #565656;
          }
          &:active {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &:disabled {
            background-color: transparent;
            color: #6a6a6a;
            border: 1px solid #6a6a6a;
            cursor: not-allowed;
          }
        `;

      case asLink != null:
        return css`
          width: auto;
          background-color: transparent;
          color: #31a7ff;
          border: none;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &:disabled {
            color: #6a6a6a;
            cursor: not-allowed;
          }
        `;

      default:
        return css`
          background-color: #2e75ff;
          border: none;
          color: #ffffff;

          &:hover {
            background-color: #31a7ff;
          }

          &:disabled {
            background-color: #6a6a6a;
            cursor: not-allowed;
          }

          &:active {
            background-color: #3361ec;
          }
        `;
    }
  }}
`;

export const ScLink = styled(Link)`
  text-decoration: none;
` as any as typeof Link;
