import { ENV } from 'env/env.config';

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function enumKeys(E: any): string[] {
  return Object.keys(E).filter((k) => typeof E[k] === 'number');
}

export function enumStringValues(E: any): string[] {
  const keys = Object.keys(E).filter((k) => typeof E[k] === 'string');
  return keys.map((k) => E[k]);
}

export function enumValues(E: any): number[] {
  return enumKeys(E).map((k) => E[k]);
}

export function isEnumContains(E: any, T: any): boolean {
  return enumValues(E).includes(T);
}

export function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

export function toCamel(s: string) {
  if (!s.includes('_')) return s;

  return s.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
}

export function toSnake(s: string) {
  return s
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
}

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function deepKeysTransformFactory(transform: (key: string) => string) {
  function deepKeysTransform<T extends { [key: string]: any } | any[]>(
    o: T,
    blacklists?: string[][]
  ): object {
    if (isObject(o)) {
      return Object.keys(o).reduce((previous, current) => {
        const transformedKey = transform(current);

        const keyValue = (o as { [key: string]: any })[current];

        if (blacklists) {
          const keyBlacklists = blacklists
            .filter((bl) => bl[0] === transformedKey)
            .map((bl) => bl.slice(1));

          if (keyBlacklists.find((bl) => bl.length === 0)) {
            previous[transformedKey] = keyValue;
          } else {
            previous[transformedKey] = deepKeysTransform(
              keyValue,
              keyBlacklists
            );
          }
        } else {
          previous[transformedKey] = deepKeysTransform(keyValue);
        }

        return previous;
      }, {} as { [key: string]: any });
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return deepKeysTransform(i, blacklists);
      });
    }

    return o;
  }

  return deepKeysTransform;
}

export function containsOnlyNumbersAndDot(str: string, precision = 2) {
  return new RegExp(`^([0-9]+(\\.([0-9]{1,${precision}})?)?)$`).test(str);
}

export const camelize = deepKeysTransformFactory(toCamel);
export const snakify = deepKeysTransformFactory(toSnake);

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function navigateToDemo(lng: string) {
  window.location.href = `${ENV.DEMO_URL}/demo/create?lng=${lng}`;
}

export function scrollViewByHash() {
  const hash = window.location.hash;
  const section = document.querySelector(hash);
  section &&
    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
}
