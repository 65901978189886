import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ScContainer } from 'components/styles/common';
import { Responsive } from 'components/Responsive';
import { MediaType } from 'helpers/enums';
import { capitalize } from 'helpers/common';

import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as MobileLogo } from 'images/mobileLogo.svg';

import { ScFooter } from './styled';

export const Footer: FC = () => {
  const { t } = useTranslation('common');

  return (
    <ScFooter>
      <ScContainer>
        <div className="footer__row">
          <div className="footer__content">
            <div className="footer__logo">
              <Responsive
                desktop={<Logo />}
                tablet={MediaType.MOBILE_S}
                mobile={MediaType.MOBILE_S}
                mobileS={<MobileLogo />}
              />
            </div>

            <div className="footer__contact">
              <p className="footer__contact__label">{capitalize(t('email'))}</p>
              <a
                href="mailto:info@vycheck.com"
                className="footer__contact__value"
              >
                info@vycheck.com
              </a>
            </div>
          </div>

          <div className="footer__copyright">
            © VCHECK, 2022. {capitalize(t('copyright'))}
          </div>
        </div>
      </ScContainer>
    </ScFooter>
  );
};
