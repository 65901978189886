export enum MediaType {
  MOBILE_S,
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum MediaQuery {
  MOBILE_S = '(max-width: 576px)',
  MOBILE = '(min-width: 576px) and (max-width: 768px)',
  TABLET = '(min-width: 768px) and (max-width: 928px)',
  DESKTOP = '(min-width: 928px)',
}
