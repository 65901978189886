import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';

export const languages = [
  {
    name: 'English',
    locale: 'en',
  },
  {
    name: 'Українська',
    locale: 'uk',
  },
  {
    name: 'Русский',
    locale: 'ru',
  },
  {
    name: 'Polski',
    locale: 'pl',
  },
];

export const availableLng = languages.map(({ locale }) => locale);

export const fallbackLng = 'en';

class CustomLanguageDetector extends LanguageDetector {
  detect(detectionOrder: DetectorOptions['order']) {
    return super.detect(detectionOrder) || fallbackLng;
  }
}

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,

  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

export default i18next
  .use(CustomLanguageDetector)
  .use(XHR)
  .use(I18nInstanceSetter)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLng,
    supportedLngs: availableLng,
    backend: {
      loadPath: `${
        process.env.NODE_ENV !== 'development' && process.env.PUBLIC_URL
          ? process.env.PUBLIC_URL
          : ''
      }/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: process.env.BUILD_VERSION },
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'lng',
    },
    ns: ['common', 'validation'],
    defaultNS: 'common',
    debug: process.env.REACT_APP_ENV !== 'production',
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
