export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '480px',
  mobileXXL: '576px',
  tablet: '768px',
  desktop: '928px',
  desktopL: '1100px',
  desktopXL: '1200px',
  desktopXXL: '1300px',
};

export const device = {
  mobileS: `max-width: ${size.mobileS}`,
  mobileM: `max-width: ${size.mobileM}`,
  mobileL: `max-width: ${size.mobileL}`,
  mobileXL: `max-width: ${size.mobileXL}`,
  mobileXXL: `max-width: ${size.mobileXXL}`,
  tablet: `max-width: ${size.tablet}`,
  desktop: `max-width: ${size.desktop}`,
  desktopL: `max-width: ${size.desktopL}`,
  desktopXL: `max-width: ${size.desktopXL}`,
  desktopXXL: `max-width: ${size.desktopXXL}`,
};
