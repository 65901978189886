import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 153px;
  padding: 57px 0;
  background-color: #232323;
  @media (${device.desktop}) {
    max-height: none;
    padding: 30px 0;
  }
  .footer {
    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      @media (${device.desktop}) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      @media (${device.mobileXXL}) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__logo {
      display: flex;
      margin-right: 60px;
    }
    &__contact {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      font-family: Manrope, sans-serif;
      @media (${device.mobileXXL}) {
        margin: 20px 0 0 0;
      }
      &__label {
        font-size: 12px;
        line-height: 1;
        color: #eaeaea;
      }
      &__value {
        margin-top: 4px;
        font-weight: bold;
        font-size: 14px;
        line-height: 1;
        text-decoration: none;
        color: #ffffff;
      }
    }
    &__copyright {
      font-family: Manrope, sans-serif;
      font-size: 14px;
      @media (${device.desktop}) {
        margin-top: 20px;
        color: #949494;
      }
    }
  }
`;
