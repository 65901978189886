import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

interface ScHeaderProps {
  activeBg: boolean;
}

export const ScHeader = styled.div<ScHeaderProps>`
  padding: 30px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  ${({ activeBg }) =>
    activeBg
      ? 'background: #2A2A2A; box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);'
      : 'background: transparent;'};
  transition: 0.6s;
  @media (${device.tablet}) {
    padding: 15px 0;
  }
  .header {
    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
    &__logo {
      display: flex;
    }
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__demoBtn {
        margin-left: 40px;
        @media (${device.tablet}) {
          margin-left: 15px;
        }
      }
    }
  }
`;
