import React from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | undefined;
  eventId: string | undefined;
}

interface ErrorBoundaryProps {
  fallback?: ((error: Error | undefined) => React.ReactNode) | React.ReactNode;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    hasError: false,
    error: undefined,
    eventId: undefined,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const { fallback } = this.props;
      if (!fallback) return null;

      if (typeof fallback === 'function') return fallback(this.state.error);

      return fallback;
    }

    return this.props.children || null;
  }
}
