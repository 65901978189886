import React, { Suspense } from 'react';
import { compose, lazy, mount } from 'navi';
import { Router, View } from 'react-navi';
import { Layout } from 'Layout';

const routes = compose(
  mount({
    '*': lazy(() => import('modules/entrypoint')),
  })
);

export const Routes = () => {
  return (
    <Router routes={routes}>
      <Layout>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Layout>
    </Router>
  );
};
