import React, { FC } from 'react';
import { Routes } from 'routes';

import { GlobalStyles } from 'components/styles/GlobalStyles';
import { ErrorBoundary } from 'components/internal/ErrorBoundary';
import { NotFoundBlock } from 'components/internal/NotFound';

const App: FC = () => {
  return (
    <>
      <GlobalStyles />

      <ErrorBoundary fallback={<NotFoundBlock />}>
        <Routes />
      </ErrorBoundary>
    </>
  );
};

export default App;
