import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScSwitchLanguage = styled.div`
  position: relative;
`;

interface ScButtonSwitchLanguageProps {
  isVisible: boolean;
}

export const ScButtonSwitchLanguage = styled.button<ScButtonSwitchLanguageProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  @media (${device.mobileXL}) {
    font-size: 12px;
    line-height: 16px;
  }
  .icon {
    svg {
      display: block;
      width: 24px;
      height: 24px;
      @media (${device.mobileXL}) {
        width: 18px;
        height: 18px;
      }
    }
  }
  .value {
    line-height: 1.19;
    margin-left: 10px;
    margin-right: 10px;
    @media (${device.mobileXL}) {
      margin-left: 9px;
      margin-right: 5px;
    }
    @media (${device.mobileL}) {
      display: none;
    }
  }
  .arrow {
    display: flex;
    transition: 0.3s;
    transform: ${({ isVisible }) =>
      isVisible ? 'rotate(180deg)' : 'rotate(0deg)'};
    @media (${device.mobileL}) {
      display: none;
    }
  }
}
`;

export const ScDropdownSwitchLanguage = styled.ul`
  position: absolute;
  top: 150%;
  right: 0;
  background: #555555;
  box-shadow: rgba(21, 20, 20, 0.4) 0 4px 12px;
  min-width: 120px;
  border-radius: 8px;
  list-style: none;
  z-index: 100;
  @media (${device.mobileXL}) {
    min-width: 90px;
  }

  & > li {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 0.9em;
    color: #efeeee;
    &:hover {
      color: #FFFFFF;
    }
  }
  }
`;
