import React from 'react';
import { NotFoundError } from 'navi';
import { useTranslation } from 'react-i18next';

import { Footer } from 'components/Footer';
import { Button } from 'components/Button';
import { Header } from 'components/Header';

import notFoundPage from 'images/notFoundPage.png';
import { ErrorBlock } from './styled';

export const NotFoundBlock = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Header />
      <ErrorBlock>
        <div className="image">
          <img src={notFoundPage} alt="" />
        </div>
        <h1>{t('notFoundPage.title')}</h1>
        <div className="button-block">
          <Button to="/">{t('notFoundPage.buttonText')}</Button>
        </div>
      </ErrorBlock>
      <Footer />
    </>
  );
};

export const NotFountLayout = (_: NotFoundError) => <NotFoundBlock />;
