import styled from 'styled-components/macro';

import { device } from 'components/Global/MediaQuery';

export const ScContainer = styled.div`
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const ScLayoutContainer = styled(ScContainer)`
  padding-top: 100px;
  padding-bottom: 150px;
  min-height: 100vh;
  @media (${device.mobileXXL}) {
    padding-bottom: 200px;
  }
  @media (${device.mobileM}) {
    padding-bottom: 250px;
  }
`;

interface ScBlockProps {
  backBtn?: boolean;
  noMargin?: boolean;
}

export const ScBlock = styled.div<ScBlockProps>`
  background-color: ${({ theme }) => theme.blockColor};
  box-shadow: ${({ theme }) => theme.blockShadow};
  max-width: 688px;
  margin: 70px auto;
  border-radius: 12px;
  padding: 56px 36px;
  position: relative;
  @media (${device.tablet}) {
    margin: 30px auto;
  }
  @media (${device.mobileXL}) {
    padding: ${({ backBtn }) =>
      backBtn ? '56px 16px 24px 16px' : '24px 16px'};
    margin: ${({ noMargin }) => (noMargin ? '0 0 10px 0' : 'none')};
  }
  @media (${device.mobileM}) {
    margin: ${({ noMargin }) => (noMargin ? '0 0 10px 0' : '20px auto')};
  }
  .info {
    h1 {
      font-size: 28px;
      line-height: 1.14;
      font-weight: 600;
      color:  ${({ theme }) => theme.primaryTextColor};
      margin-bottom: 24px;
      @media (${device.mobileXL}) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
    p {
      color:  ${({ theme }) => theme.secondaryTextColor};
    }
  }
  .button-block {
    margin-top: 38px;
    text-align: center;
    display: flex;
    justify-content: center;
    @media (${device.mobileXL}) {
      flex-direction: column;
      margin-top: 20px;
      & button {
        flex-grow: 1;
      }
    }
    & button:nth-child(2) {
      margin-left: 14px;
      @media (${device.mobileXL}) {
        margin-left: 0;
        margin-top: 15px;
      }
      }
    }
  }
  .arrow-back {
    display: block;
    position: absolute;
    background-color: transparent;
    top: 22px;
    left: 36px;
    width: 16px;
    height: 16px;
    border: none;
    cursor: pointer;
    @media (${device.mobileXL}) {
      left: 16px;
    }
  }
`;
