import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from 'i18n';

import { ReactComponent as ArrowIcon } from 'images/arrowIcon.svg';
import { ReactComponent as LangIcon } from 'images/langIcon.svg';
import { useComponentVisible } from 'hooks/visibility';
import { useLanguageChange } from 'hooks/translation';
import {
  ScButtonSwitchLanguage,
  ScDropdownSwitchLanguage,
  ScSwitchLanguage,
} from './styled';

export const SwitchLanguage = () => {
  const [changeLanguage] = useLanguageChange();

  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOpen = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleChangeLanguage = (lang: string) => {
    changeLanguage(lang);
    handleOpen();
  };

  return (
    <ScSwitchLanguage>
      <ButtonSwitchLanguage
        ref={openerRef}
        onClick={handleOpen}
        isVisible={isComponentVisible}
      />
      {isComponentVisible && (
        <DropdownSwitchLanguage ref={ref} onChange={handleChangeLanguage} />
      )}
    </ScSwitchLanguage>
  );
};

const ButtonSwitchLanguage = forwardRef<
  HTMLButtonElement,
  { onClick: () => void; isVisible: boolean }
>((props, ref) => {
  const {
    i18n: { language },
  } = useTranslation();

  const currentLanguage = useMemo(() => {
    return languages.find(({ locale }) => locale === language.slice(0, 2));
  }, [language]);

  return (
    <ScButtonSwitchLanguage ref={ref} {...props}>
      <div className="icon">
        <LangIcon />
      </div>
      <div className="value">{currentLanguage?.name}</div>
      <div className="arrow">
        <ArrowIcon />
      </div>
    </ScButtonSwitchLanguage>
  );
});

const DropdownSwitchLanguage = forwardRef<
  HTMLUListElement,
  { onChange: (value: string) => void }
>(({ onChange }, ref) => {
  return (
    <ScDropdownSwitchLanguage ref={ref}>
      {languages.map(({ name, locale }) => (
        <li key={locale} onClick={() => onChange(locale)}>
          {name}
        </li>
      ))}
    </ScDropdownSwitchLanguage>
  );
});
