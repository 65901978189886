import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScContainer } from 'components/styles/common';
import { Responsive } from 'components/Responsive';
import { SwitchLanguage } from 'components/SwitchLanguage';
import { Button } from 'components/Button';
import { MediaType } from 'helpers/enums';
import { capitalize, navigateToDemo } from 'helpers/common';

import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as MobileLogo } from 'images/mobileLogo.svg';

import { ScHeader } from './styled';

export const Header: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  const [backgroundState, setBackgroundState] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setBackgroundState(window.scrollY >= 50);
    });
  }, []);

  return (
    <ScHeader activeBg={backgroundState}>
      <ScContainer>
        <div className="header__row">
          <div className="header__logo">
            <Responsive
              desktop={MediaType.TABLET}
              tablet={<Logo />}
              mobile={MediaType.MOBILE_S}
              mobileS={<MobileLogo />}
            />
          </div>
          <div className="header__content">
            <SwitchLanguage />
            <Button
              className="header__content__demoBtn"
              small
              onClick={() => navigateToDemo(language)}
            >
              <Responsive
                desktop={MediaType.MOBILE}
                tablet={MediaType.MOBILE}
                mobile={<>{capitalize(t('tryDemo'))}</>}
                mobileS={<>{capitalize(t('demo'))}</>}
              />
            </Button>
          </div>
        </div>
      </ScContainer>
    </ScHeader>
  );
};
