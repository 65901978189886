import { createGlobalStyle } from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.5;
    background-color: #2A2A2A;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    transition: 0.3s;

    @media (${device.mobileXL}) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  
  #root {
    position: relative;
    overflow-x: hidden;
  }

  button, input, textarea {
    font-family: 'Montserrat', sans-serif;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7a7979;
    border-radius: 10px;
  }
`;
