import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundBoundary, useCurrentRoute } from 'react-navi';

import { NotFountLayout } from 'components/internal/NotFound';
import { GlobalTooltip } from 'modules/globalTooltip';

const useTitleSetter = () => {
  const { title }: any = useCurrentRoute();

  const { t } = useTranslation();

  useEffect(() => {
    switch (typeof title) {
      case 'function': {
        document.title = title(t);
        break;
      }
      case 'string': {
        document.title = title;
        break;
      }
      default: {
        document.title = 'VCHECK';
      }
    }
  }, [title, t]);
};

export const Layout: FC = ({ children }) => {
  useTitleSetter();

  return (
    <>
      <GlobalTooltip />

      <NotFoundBoundary render={NotFountLayout}>{children}</NotFoundBoundary>
    </>
  );
};
