import styled from 'styled-components/macro';
import { device } from 'components/Global/MediaQuery';

export const ScResult = styled.div`
  .image {
    text-align: center;
    svg,
    img {
      width: 100%;
      max-width: 340px;
    }
  }

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.25;
    margin-top: 12px;
    color: ${({ theme }) => theme.primaryTextColor};
    @media (${device.mobileXL}) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .text {
    text-align: center;
    margin-top: 15px;
    color: ${({ theme }) => theme.secondaryTextColor};
    padding: 0 50px;
    @media (${device.mobileXL}) {
      padding: 0;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      @media (${device.mobileXL}) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .button-block {
    margin-top: 68px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (${device.mobileXXL}) {
      margin-top: 40px;
      flex-direction: column-reverse;
    }
    & > * {
      margin: 0 12px;
      @media (${device.mobileXXL}) {
        margin: 5px 0;
      }
    }
  }

  .btn-approve {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`;

export const ErrorBlock = styled.div`
  max-width: 1296px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  padding: 100px 15px 180px 15px;
  text-align: center;
  .image {
    svg,
    img {
      max-width: 474px;
      width: 100%;
    }
  }
  h1 {
    margin-top: 36px;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.25;
    color: ${({ theme }) => theme.primaryTextColor};
  }
  .button-block {
    margin-top: 29px;
  }
`;
